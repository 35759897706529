import React, { useState, useEffect } from 'react';
import '../styles/ProfileViewUI.css';

interface UserProfile {
    name: string;
    age: number;
    height: number;
    weight: number;
    gender: 'male' | 'female' | 'other';
    activityLevel: 'sedentary' | 'light' | 'moderate' | 'active' | 'very active';
}

const ProfileView: React.FC = () => {
    const [profile, setProfile] = useState<UserProfile>({
        name: '',
        age: 0,
        height: 0,
        weight: 0,
        gender: 'other',
        activityLevel: 'moderate'
    });

    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const savedProfile = localStorage.getItem('userProfile');
        if (savedProfile) {
            setProfile(JSON.parse(savedProfile));
        }
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setProfile(prevProfile => ({
            ...prevProfile,
            [name]: name === 'age' || name === 'height' || name === 'weight' ? Number(value) : value
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        localStorage.setItem('userProfile', JSON.stringify(profile));
        setIsEditing(false);
    };

    return (
        <div className="profile-view">
            <h1>User Profile</h1>
            {isEditing ? (
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={profile.name}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="age">Age:</label>
                        <input
                            type="number"
                            id="age"
                            name="age"
                            value={profile.age}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="height">Height (cm):</label>
                        <input
                            type="number"
                            id="height"
                            name="height"
                            value={profile.height}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="weight">Weight (kg):</label>
                        <input
                            type="number"
                            id="weight"
                            name="weight"
                            value={profile.weight}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="gender">Gender:</label>
                        <select
                            id="gender"
                            name="gender"
                            value={profile.gender}
                            onChange={handleInputChange}
                        >
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="activityLevel">Activity Level:</label>
                        <select
                            id="activityLevel"
                            name="activityLevel"
                            value={profile.activityLevel}
                            onChange={handleInputChange}
                        >
                            <option value="sedentary">Sedentary</option>
                            <option value="light">Light</option>
                            <option value="moderate">Moderate</option>
                            <option value="active">Active</option>
                            <option value="very active">Very Active</option>
                        </select>
                    </div>
                    <button type="submit">Save Profile</button>
                </form>
            ) : (
                <div>
                    <p><strong>Name:</strong> {profile.name}</p>
                    <p><strong>Age:</strong> {profile.age}</p>
                    <p><strong>Height:</strong> {profile.height} cm</p>
                    <p><strong>Weight:</strong> {profile.weight} kg</p>
                    <p><strong>Gender:</strong> {profile.gender}</p>
                    <p><strong>Activity Level:</strong> {profile.activityLevel}</p>
                    <button onClick={() => setIsEditing(true)}>Edit Profile</button>
                </div>
            )}
        </div>
    );
};

export default ProfileView;
