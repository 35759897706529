import React from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../../utils/firebase';

const SignIn: React.FC = () => {
    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                console.log('Signed in with Google:', result.user);
            })
            .catch((error) => {
                console.error('Google sign-in error:', error);
            });
    };

    return (
        <div>
            <h1>Sign In</h1>
            <button onClick={signInWithGoogle}>Sign in with Google</button>
        </div>
    );
};

export default SignIn;
