import React, { useState } from 'react';
import '../../styles/MealFormUI.css';

export interface Meal {
    id: string;
    name: string;
    calories: number;
    protein: number;
    carbs: number;
    fat: number;
}

interface MealFormProps {
    onSubmit: (meal: Meal) => void;
    initialMeal?: Meal;
}

const MealFormLogic: React.FC<MealFormProps> = ({ onSubmit, initialMeal }) => {
    const [meal, setMeal] = useState<Meal>(
        initialMeal || {
            id: '',
            name: '',
            calories: 0,
            protein: 0,
            carbs: 0,
            fat: 0,
        }
    );
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const validateForm = (): boolean => {
        const newErrors: { [key: string]: string } = {};

        if (!meal.name.trim()) {
            newErrors.name = 'Meal name is required';
        }
        if (meal.calories <= 0) {
            newErrors.calories = 'Calories must be greater than 0';
        }
        if (meal.protein < 0) {
            newErrors.protein = 'Protein cannot be negative';
        }
        if (meal.carbs < 0) {
            newErrors.carbs = 'Carbs cannot be negative';
        }
        if (meal.fat < 0) {
            newErrors.fat = 'Fat cannot be negative';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (validateForm()) {
            onSubmit({ ...meal, id: meal.id || Date.now().toString() });
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setMeal({ ...meal, [name]: name === 'name' ? value : Number(value) });
    };

    return (
        <form onSubmit={handleSubmit} className="meal-form">
            <div className="form-group">
                <label htmlFor="name">Meal Name:</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={meal.name}
                    onChange={handleChange}
                />
                {errors.name && <span className="error">{errors.name}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="calories">Calories:</label>
                <input
                    type="number"
                    id="calories"
                    name="calories"
                    value={meal.calories}
                    onChange={handleChange}
                />
                {errors.calories && <span className="error">{errors.calories}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="protein">Protein (g):</label>
                <input
                    type="number"
                    id="protein"
                    name="protein"
                    value={meal.protein}
                    onChange={handleChange}
                />
                {errors.protein && <span className="error">{errors.protein}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="carbs">Carbs (g):</label>
                <input
                    type="number"
                    id="carbs"
                    name="carbs"
                    value={meal.carbs}
                    onChange={handleChange}
                />
                {errors.carbs && <span className="error">{errors.carbs}</span>}
            </div>
            <div className="form-group">
                <label htmlFor="fat">Fat (g):</label>
                <input
                    type="number"
                    id="fat"
                    name="fat"
                    value={meal.fat}
                    onChange={handleChange}
                />
                {errors.fat && <span className="error">{errors.fat}</span>}
            </div>
            <button type="submit">Save Meal</button>
        </form>
    );
};

export default MealFormLogic;
