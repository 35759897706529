import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import { User } from 'firebase/auth';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from './utils/firebase';

import SignIn from './components/logic/LoginPageLogic';
import TodayView from './components/logic/TodayViewLogic';
import HistoryView from './components/HistoryView';
import SettingsView from './components/logic/SettingsViewLogic';
import ProfileView from './components/ProfileView';
import SearchView from './components/SearchView';
import BurgerMenu from './components/BurgerMenu';
import './App.css';

// import SignIn from './components/SignIn';
// import TodayView from './components/TodayView';
// import HistoryView from './components/HistoryView';
// import SettingsView from './components/SettingsView';
// import ProfileView from './components/ProfileView';
// import SearchView from './components/SearchView';
// import BurgerMenu from './components/BurgerMenu';
// import './App.css';

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const handleSignOut = () => {
    signOut(auth).then(() => {
      console.log('User signed out');
    }).catch((error) => {
      console.error('Sign out error:', error);
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        {user && (
          <>
            <BurgerMenu />
            <button onClick={handleSignOut} className="sign-out-button">Sign Out</button>
          </>
        )}
        <Routes>
          <Route path="/signin" element={user ? <Navigate to="/" /> : <SignIn />} />
          <Route path="/" element={user ? <TodayView /> : <Navigate to="/signin" />} />
          <Route path="/history" element={user ? <HistoryView /> : <Navigate to="/signin" />} />
          <Route path="/search" element={user ? <SearchView /> : <Navigate to="/signin" />} />
          <Route path="/profile" element={user ? <ProfileView /> : <Navigate to="/signin" />} />
          <Route path="/settings" element={user ? <SettingsView /> : <Navigate to="/signin" />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
