import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './BurgerMenu.css';

const BurgerMenu: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="burger-menu">
            <button
                className="burger-icon"
                onClick={toggleMenu}
                aria-label={isOpen ? "Close menu" : "Open menu"}
            >
                <span></span>
                <span></span>
                <span></span>
            </button>
            <nav className={`menu-items ${isOpen ? 'open' : ''}`}>
                <Link to="/" onClick={toggleMenu}>Today</Link>
                <Link to="/history" onClick={toggleMenu}>History</Link>
                <Link to="/search" onClick={toggleMenu}>Search</Link>
                <Link to="/profile" onClick={toggleMenu}>Profile</Link>
                <Link to="/settings" onClick={toggleMenu}>Settings</Link>
                <Link to="/about" onClick={toggleMenu}>About v0.21</Link>
            </nav>
        </div>
    );
};

export default BurgerMenu
