import React from 'react';
import '../../styles/DailySummaryUI.css';

type DailySummaryProps = {
    consumed: {
        calories: number;
        protein: number;
        carbs: number;
        fat: number;
    };
    goals: {
        calories: number;
        protein: number;
        carbs: number;
        fat: number;
    };
};

const DailySummary: React.FC<DailySummaryProps> = ({ consumed, goals }) => {
    return (
        <div className="daily-summary">
            <h2>Daily Summary</h2>
            <div className="summary-row">
                <span>Calories: </span>
                <span>{consumed.calories} / {goals.calories}</span>
            </div>
            <div className="summary-row">
                <span>Protein: </span>
                <span>{consumed.protein}g / {goals.protein}g</span>
            </div>
            <div className="summary-row">
                <span>Carbs: </span>
                <span>{consumed.carbs}g / {goals.carbs}g</span>
            </div>
            <div className="summary-row">
                <span>Fat: </span>
                <span>{consumed.fat}g / {goals.fat}g</span>
            </div>
        </div>
    );
};

export default DailySummary;
