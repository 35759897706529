import React from 'react';
import '../../styles/SavedOptionsComponentUI.css';

type SavedOption = {
    id: string;
    name: string;
    calories: number;
    protein: number;
    carbs: number;
    fat: number;
};

type SavedOptionsComponentProps = {
    onOptionSelected: (option: SavedOption) => void;
};

const SavedOptionsComponent: React.FC<SavedOptionsComponentProps> = ({ onOptionSelected }) => {
    const dummyOptions: SavedOption[] = [
        { id: '1', name: 'Chicken Salad', calories: 200, protein: 30, carbs: 10, fat: 5 },
        { id: '2', name: 'Beef Steak', calories: 300, protein: 40, carbs: 20, fat: 15 },
        // Add more dummy options as needed
    ];

    return (
        <div className="saved-options-component">
            <h2>Saved Options</h2>
            <ul>
                {dummyOptions.map(option => (
                    <li key={option.id} onClick={() => onOptionSelected(option)}>
                        <div className="option-name">{option.name}</div>
                        <div className="option-nutrients">
                            {option.calories} cal, P: {option.protein}g, C: {option.carbs}g, F: {option.fat}g
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SavedOptionsComponent;
