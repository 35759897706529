import React, { useRef, useState } from 'react';
import '../../styles/CameraComponentUI.css';

type CameraComponentProps = {
    onPhotoTaken: (photo: Blob) => void;
};

const CameraComponent: React.FC<CameraComponentProps> = ({ onPhotoTaken }) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [facingMode, setFacingMode] = useState<'user' | 'environment'>('environment');

    const startCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: { facingMode: facingMode }
            });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            }
        } catch (err) {
            console.error("Error accessing the camera:", err);
        }
    };

    const stopCamera = () => {
        if (videoRef.current && videoRef.current.srcObject) {
            const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
            tracks.forEach(track => track.stop());
        }
    };

    const takePhoto = () => {
        if (canvasRef.current && videoRef.current) {
            const context = canvasRef.current.getContext('2d');
            if (context) {
                context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
                canvasRef.current.toBlob((blob) => {
                    if (blob) {
                        onPhotoTaken(blob);
                    }
                }, 'image/jpeg');
            }
        }
    };

    const switchCamera = () => {
        stopCamera();
        setFacingMode(prevMode => prevMode === 'user' ? 'environment' : 'user');
        startCamera();
    };

    return (
        <div className="camera-component">
            <video ref={videoRef} style={{ width: '100%' }} />
            <canvas ref={canvasRef} style={{ display: 'none' }} width="640" height="480" />
            <div className="camera-controls">
                <button onClick={startCamera}>Start Camera</button>
                <button onClick={takePhoto}>Take Photo</button>
                <button onClick={stopCamera}>Stop Camera</button>
                <button onClick={switchCamera}>Switch Camera</button>
            </div>
        </div>
    );
};

export default CameraComponent;