import { auth } from '../utils/firebase';

export interface Meal {
    id: string;
    name: string;
    calories: number;
    protein: number;
    carbs: number;
    fat: number;
    date: string;
}

const getUserMealsKey = (userId: string) => `meals_${userId}`;

export const saveMeal = (meal: Meal): void => {
    const user = auth.currentUser;
    if (!user) throw new Error('No user logged in');

    const meals = getMeals();
    meals.push(meal);
    localStorage.setItem(getUserMealsKey(user.uid), JSON.stringify(meals));
};

export const getMeals = (): Meal[] => {
    const user = auth.currentUser;
    if (!user) throw new Error('No user logged in');

    const mealsJson = localStorage.getItem(getUserMealsKey(user.uid));
    return mealsJson ? JSON.parse(mealsJson) : [];
};

export const getMealsByDate = (date: string): Meal[] => {
    const user = auth.currentUser;
    if (!user) throw new Error('No user logged in');

    return getMeals().filter(meal => meal.date === date);
};

export const clearMeals = (): void => {
    const user = auth.currentUser;
    if (!user) throw new Error('No user logged in');

    localStorage.removeItem(getUserMealsKey(user.uid));
};

export const removeMeal = (id: string): void => {
    const user = auth.currentUser;
    if (!user) throw new Error('No user logged in');

    const meals = getMeals().filter(meal => meal.id !== id);
    localStorage.setItem(getUserMealsKey(user.uid), JSON.stringify(meals));
};

export const updateMeal = (updatedMeal: Meal): void => {
    const user = auth.currentUser;
    if (!user) throw new Error('No user logged in');

    const meals = getMeals().map(meal =>
        meal.id === updatedMeal.id ? updatedMeal : meal
    );
    localStorage.setItem(getUserMealsKey(user.uid), JSON.stringify(meals));
};

export const searchMeals = (query: string): Meal[] => {
    const user = auth.currentUser;
    if (!user) throw new Error('No user logged in');

    const allMeals = getMeals();
    return allMeals.filter(meal =>
        meal.name.toLowerCase().includes(query.toLowerCase())
    );
};
