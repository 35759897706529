import React, { useState } from 'react';
import { Meal, searchMeals } from '../services/mealService';
import '../styles/SearchViewUI.css';

const SearchView: React.FC = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState<Meal[]>([]);
    const [error, setError] = useState<string | null>(null);

    const handleSearch = () => {
        try {
            const searchResults = searchMeals(query);
            setResults(searchResults);
            setError(null);
        } catch (err) {
            setError('Failed to search meals. Please make sure you are logged in.');
            setResults([]);
        }
    };

    return (
        <div className="search-view">
            <h1>Search Meals</h1>
            <div className="search-bar">
                <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Enter meal name"
                />
                <button onClick={handleSearch}>Search</button>
            </div>
            {error && <p className="error-message">{error}</p>}
            <div className="search-results">
                {results.length === 0 ? (
                    <p>No results found</p>
                ) : (
                    <ul>
                        {results.map((meal) => (
                            <li key={meal.id}>
                                <h3>{meal.name}</h3>
                                <p>Date: {meal.date}</p>
                                <p>Calories: {meal.calories}</p>
                                <p>Protein: {meal.protein}g, Carbs: {meal.carbs}g, Fat: {meal.fat}g</p>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default SearchView;
