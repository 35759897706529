import React, { useState, useEffect } from 'react';
import { collection, query, where, addDoc, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../../utils/firebase';
import CameraComponent from '../UI/CameraComponentUI';
import SavedOptionsComponent from '../UI/SavedOptionsComponentUI';
import RecommendationComponent from '../UI/RecommendationComponentUI';
import DailySummary from '../UI/DailySummaryUI';
import MealForm from '../UI/MealFormUI';
import '../../styles/TodayViewUI.css';

interface Meal {
    id: string;
    name: string;
    calories: number;
    protein: number;
    carbs: number;
    fat: number;
    date: string;
    userId: string;
}

const TodayViewLogic: React.FC = () => {
    const [meals, setMeals] = useState<Meal[]>([]);
    const [showCamera, setShowCamera] = useState(false);
    const [showSavedOptions, setShowSavedOptions] = useState(false);
    const [showRecommendations, setShowRecommendations] = useState(false);
    const [showMealForm, setShowMealForm] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const dailyGoals = {
        calories: 2000,
        protein: 100,
        carbs: 250,
        fat: 65
    };

    useEffect(() => {
        const user = auth.currentUser;
        if (!user) {
            setError('No user logged in');
            return;
        }

        const today = new Date().toISOString().split('T')[0];
        const q = query(
            collection(db, 'meals'),
            where('userId', '==', user.uid),
            where('date', '==', today)
        );

        const unsubscribe = onSnapshot(q,
            (querySnapshot) => {
                const fetchedMeals: Meal[] = [];
                querySnapshot.forEach((doc) => {
                    fetchedMeals.push({ id: doc.id, ...doc.data() } as Meal);
                });
                setMeals(fetchedMeals);
                setError(null);
            },
            (err) => {
                setError('Failed to load meals. Please try again.');
                console.error("Firestore real-time sync error: ", err);
            }
        );

        // Cleanup function
        return () => unsubscribe();
    }, []);  // Empty dependency array means this effect runs once on mount

    const consumedNutrients = meals.reduce((acc, meal) => ({
        calories: acc.calories + meal.calories,
        protein: acc.protein + meal.protein,
        carbs: acc.carbs + meal.carbs,
        fat: acc.fat + meal.fat
    }), { calories: 0, protein: 0, carbs: 0, fat: 0 });

    const handleTakePhotoClick = () => {
        setShowCamera(true);
        setShowSavedOptions(false);
        setShowRecommendations(false);
        setShowMealForm(false);
    };

    const handlePhotoTaken = async (photo: Blob) => {
        console.log('Photo taken:', photo);
        setShowCamera(false);

        try {
            const user = auth.currentUser;
            if (!user) {
                setError('No user logged in');
                return;
            }

            const newMeal: Omit<Meal, 'id'> = {
                name: "Photo meal",
                calories: 300,
                protein: 20,
                carbs: 30,
                fat: 10,
                date: new Date().toISOString().split('T')[0],
                userId: user.uid
            };

            const docRef = await addDoc(collection(db, 'meals'), newMeal);
            setMeals([...meals, { ...newMeal, id: docRef.id }]);
            setError(null);
        } catch (err) {
            setError('Failed to save meal. Please try again.');
        }
    };

    const handleSelectOptionClick = () => {
        setShowSavedOptions(true);
        setShowCamera(false);
        setShowRecommendations(false);
        setShowMealForm(false);
    };

    const handleOptionSelected = async (option: Omit<Meal, 'id' | 'date' | 'userId'>) => {
        try {
            const user = auth.currentUser;
            if (!user) {
                setError('No user logged in');
                return;
            }

            const newMeal: Omit<Meal, 'id'> = {
                ...option,
                date: new Date().toISOString().split('T')[0],
                userId: user.uid
            };

            const docRef = await addDoc(collection(db, 'meals'), newMeal);
            setMeals([...meals, { ...newMeal, id: docRef.id }]);
            setShowSavedOptions(false);
            setError(null);
        } catch (err) {
            setError('Failed to save meal. Please try again.');
        }
    };

    const handleGetRecommendationClick = () => {
        setShowRecommendations(true);
        setShowCamera(false);
        setShowSavedOptions(false);
        setShowMealForm(false);
    };

    const handleRecommendationSelected = async (recommendation: Omit<Meal, 'id' | 'date' | 'userId'>) => {
        try {
            const user = auth.currentUser;
            if (!user) {
                setError('No user logged in');
                return;
            }

            const newMeal: Omit<Meal, 'id'> = {
                ...recommendation,
                date: new Date().toISOString().split('T')[0],
                userId: user.uid
            };

            const docRef = await addDoc(collection(db, 'meals'), newMeal);
            setMeals([...meals, { ...newMeal, id: docRef.id }]);
            setShowRecommendations(false);
            setError(null);
        } catch (err) {
            setError('Failed to save meal. Please try again.');
        }
    };

    const handleAddManualMealClick = () => {
        setShowMealForm(true);
        setShowCamera(false);
        setShowSavedOptions(false);
        setShowRecommendations(false);
    };

    const handleMealSubmit = async (mealData: Omit<Meal, 'id' | 'date' | 'userId'>) => {
        try {
            const user = auth.currentUser;
            if (!user) {
                setError('No user logged in');
                return;
            }

            const newMeal: Omit<Meal, 'id'> = {
                ...mealData,
                date: new Date().toISOString().split('T')[0],
                userId: user.uid
            };

            const docRef = await addDoc(collection(db, 'meals'), newMeal);
            setMeals([...meals, { ...newMeal, id: docRef.id }]);
            setShowMealForm(false);
            setError(null);
        } catch (err) {
            setError('Failed to save meal. Please try again.');
        }
    };

    const handleRemoveMeal = async (id: string) => {
        try {
            await deleteDoc(doc(db, 'meals', id));
            setMeals(meals.filter(meal => meal.id !== id));
            setError(null);
        } catch (err) {
            setError('Failed to remove meal. Please try again.');
        }
    };

    return (
        <div className="today-view">
            <h1>Today's View</h1>
            {error && <p className="error-message">{error}</p>}
            <DailySummary consumed={consumedNutrients} goals={dailyGoals} />
            <div className="action-buttons">
                <button onClick={handleTakePhotoClick}>Take Photo</button>
                <button onClick={handleSelectOptionClick}>Select Option</button>
                <button onClick={handleGetRecommendationClick}>Get Recommendation</button>
                <button onClick={handleAddManualMealClick} className="add-meal-button">+</button>
            </div>
            {showCamera && <CameraComponent onPhotoTaken={handlePhotoTaken} />}
            {showSavedOptions && <SavedOptionsComponent onOptionSelected={handleOptionSelected} />}
            {showRecommendations && (
                <RecommendationComponent
                    onRecommendationSelected={handleRecommendationSelected}
                    dailyGoals={dailyGoals}
                    consumedNutrients={consumedNutrients}
                />
            )}
            {showMealForm && <MealForm onSubmit={handleMealSubmit} />}
            <div className="meals-list">
                <h2>Meals</h2>
                {meals.length === 0 ? (
                    <p>No meals added yet.</p>
                ) : (
                    <ul>
                        {meals.map((meal) => (
                            <li key={meal.id} className="meal-item">
                                <div className="meal-details">
                                    <span className="meal-name">{meal.name}</span>
                                    <span className="meal-nutrients">
                                        {meal.calories} cal, P: {meal.protein}g, C: {meal.carbs}g, F: {meal.fat}g
                                    </span>
                                </div>
                                <button onClick={() => handleRemoveMeal(meal.id)} className="remove-meal">×</button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default TodayViewLogic;
