import React from 'react';

const HistoryView: React.FC = () => {
    return (
        <div>
            <h1>Meal History</h1>
            <p>Here you will see your past meals and nutritional data.</p>
        </div>
    );
};

export default HistoryView;