import React from 'react';
import '../../styles/RecommendationComponentUI.css';

type SavedOption = {
    id: string;
    name: string;
    calories: number;
    protein: number;
    carbs: number;
    fat: number;
};

type RecommendationComponentProps = {
    onRecommendationSelected: (recommendation: SavedOption) => void;
    dailyGoals: { calories: number; protein: number; carbs: number; fat: number; };
    consumedNutrients: { calories: number; protein: number; carbs: number; fat: number; };
};

const RecommendationComponent: React.FC<RecommendationComponentProps> = ({ onRecommendationSelected, dailyGoals, consumedNutrients }) => {
    const dummyRecommendations: SavedOption[] = [
        { id: '1', name: 'Grilled Chicken', calories: 250, protein: 35, carbs: 5, fat: 10 },
        { id: '2', name: 'Vegetable Stir Fry', calories: 150, protein: 10, carbs: 20, fat: 5 },
        // Add more dummy recommendations as needed
    ];

    return (
        <div className="recommendation-component">
            <h2>Recommendations</h2>
            <ul>
                {dummyRecommendations.map(recommendation => (
                    <li key={recommendation.id} onClick={() => onRecommendationSelected(recommendation)}>
                        <div className="recommendation-name">{recommendation.name}</div>
                        <div className="recommendation-nutrients">
                            {recommendation.calories} cal, P: {recommendation.protein}g, C: {recommendation.carbs}g, F: {recommendation.fat}g
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RecommendationComponent;
